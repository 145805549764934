import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"
import { Link } from "gatsby"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function Saison2021() {
  const [dateOpen, toggleDate] = useToggle();
  const [ticketOpen, toggleTicket] = useToggle();
  
  
  return (
    <Layout title="Saison 2021">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        strong {
          color: black;
        }

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Saisonprogramm 2020/2021</h1>
        <p>
        <p>Freitag, 2. Oktober 2020, 19:30 Uhr (GV vorg&auml;ngig um 18:15 Uhr)<br />Kult-X Kreuzlingen, Hafenstrasse 8<br /><strong>GV der GML Kreuzlingen und 1. Abonnementskonzert</strong></p>
        <p class="s3"><strong>TRIO ECLIPSE &ndash; Lionel Andrey, Klarinette; Sebastian Braun, Violoncello; Benedek Horvath, Klavier</strong><br />Werke von Schumann, Brahms, Gershwin, Schnyder</p>
        <hr />
        <p>Samstag, 21. November 2020, 19:30 Uhr<br />Campus Aula, PMS Kreuzlingen, Schulstrasse 39<br /><strong>2. Abonnementskonzert &ndash; &bdquo;100 Jahre Armin Schibler&ldquo;Kammerphilharmonie Graub&uuml;nden; Mario Schwarz, Leitung; Letizia Scherrer, Sopran; Thomas Schibler, Fagott; Christian Sprecher, Sprecher</strong><br />Werke von Beethoven und Schibler</p>
        <hr />
        <p>Sonntag, 10. Januar 2021, 15 Uhr<br />Kult-X Kreuzlingen<br /><strong>3. Abonnementskonzert &bdquo;Neujahrskonzert</strong>&ldquo;</p>
        <p>f&auml;llt aus</p>
        <hr class="is-style-wide wp-block-separator" />
        <p>Donnerstag, 25. Februar 2021, 19:30 Uhr<br />Kult-X Kreuzlingen, Hafenstrasse 8<br />4. Abonnementskonzert &ndash; Klavierrezital</p>
        <p class="s3"><strong>Georgiana Pletea, Klavier</strong><br />Werke von Beethoven, Chopin, Schibler und Ravel</p>
        <p>wird zu einem sp&auml;teren Zeitpunkt nachgeholt</p>
        <hr />
        <p>Dienstag, 30. M&auml;rz 2021, 19:30 Uhr<br />Stucksaal Museum Rosenegg, B&auml;renstrasse 6&nbsp;<br />Lesung&nbsp;&amp;&nbsp;Musik&nbsp;</p>
        <p class="s3"><strong>Ilma Rakusa liest aus &bdquo;Langsames Licht&ldquo;&nbsp;und &bdquo;Mein Alphabet&ldquo;&nbsp;</strong><br />Lara Gwen M&uuml;ller, Klavier</p>
        <div>f&auml;llt aus</div>
        <hr />
        <p>Sonntag, 9. Mai 2021, 18:00 Uhr<br />Evangelische Kirche T&auml;gerwilen&nbsp;<br />5. Abonnementskonzert &ndash; Bodenseefestival&nbsp;</p>
        <p class="s3"><strong>"Wenn ich ein V&ouml;glein w&auml;r" &ndash; Romantische Lieder und Duette von Schubert, Mendelssohn, Schumann und Brahms</strong><br />Barbara Zinniker, Sopran - Raphael Jud, Bariton - Benjamin Engeli, Klavier</p>
        <hr />
        <p>Sonntag, 13. Juni 2021, 19:30 Uhr<br />Atrium der PMS Kreuzlingen, Schulstr. 39 (Bei schlechtem Wetter: Kult-X Kreuzlingen, Hafenstrasse 8)<br />SERENADE</p>
        <p class="s3"><strong>H&auml;ndel in Harlem&nbsp;</strong><br />Daniel Schnyder, Saxophon; Adam Taubitz, Violine; Domenico Catalano, Bassposaune</p>
        
        </p>

        <div css={css`margin: 4em 0 1em 0;`}>
          <Link to="/konzerte" css={css`text-decoration: none; color: ${color.secondaryDark};`}>{`>`} Vergangene Konzerte</Link><br/>
        </div>

        <Footer marginTop={false}/>
      </main>
    </Layout>
  )
}
